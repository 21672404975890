import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  addDoc,
  serverTimestamp,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import UserAPI from "./UserApi";

class MatchApi {
  static collectionName = "matches";
  static async addMessageToChat(
    docId,
    nestedCollectionName,
    message,
    collectionName = MatchApi.collectionName
  ) {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error("Document not found");
      }

      const nestedCollectionRef = collection(docRef, nestedCollectionName);
      message = { ...message, timestamp: serverTimestamp() };
      await addDoc(nestedCollectionRef, message);

      return { success: true, message: "Message added successfully" };
    } catch (error) {
      throw error;
    }
  }

  static getMatchChat(
    docId,
    nestedCollectionName,
    callback,
    collectionName = MatchApi.collectionName
  ) {
    try {
      const docRef = doc(db, collectionName, docId);

      // Set up the main document listener
      const unsubDoc = onSnapshot(docRef, async (docSnap) => {
        if (!docSnap.exists()) {
          callback(new Error("Document not found"), null);
          return;
        }

        const docData = docSnap.data();

        if (!nestedCollectionName) {
          callback(null, docData);
          return;
        }

        // Set up the nested collection listener
        const nestedCollectionRef = collection(docRef, nestedCollectionName);
        const nestedCollectionQuery = query(
          nestedCollectionRef,
          orderBy("timestamp")
        );

        const unsubNested = onSnapshot(
          nestedCollectionQuery,
          (nestedCollectionSnap) => {
            const nestedCollectionData = nestedCollectionSnap.docs.map(
              (doc) => ({
                id: doc.id,
                ...doc.data(),
              })
            );

            callback(null, {
              ...docData,
              [nestedCollectionName]: nestedCollectionData,
            });
          }
        );

        // Return both unsubscribes so they can be used to detach listeners
        callback(null, { unsubDoc, unsubNested });
      });
    } catch (error) {
      callback(error, null);
    }
  }

  static fetchMatches(user, callback) {
    if (!user) {
      callback([], false);
      return;
    }

    const userID = user.userID;
    const matchesRef = collection(db, "matches");
    const q = query(matchesRef, where("users", "array-contains", userID));

    const unsubscribeFromMatches = onSnapshot(
      q,
      (querySnapshot) => {
        const matchesList = [];
        const chatUnsubscribers = [];
        if (querySnapshot.empty) return callback([], true);

        querySnapshot.forEach((matchDoc) => {
          const matchData = matchDoc.data();
          const matchID = matchDoc.id;

          // Determine the other user's ID
          const otherUserID = matchData.users.find((uid) => uid !== userID);

          // Fetch the other user's data
          UserAPI.getUserByID(otherUserID)
            .then((otherUser) => {
              // Create a listener for the Chat subcollection
              const chatRef = collection(db, `matches/${matchID}/Chat`);
              const lastMessageQuery = query(
                chatRef,
                orderBy("timestamp", "desc"),
                limit(1)
              );

              const unsubscribeFromChat = onSnapshot(
                lastMessageQuery,
                (chatSnapshot) => {
                  let lastMessage = null;
                  let lastMessageTimestamp = null;

                  if (!chatSnapshot.empty) {
                    const lastMessageDoc = chatSnapshot.docs[0];
                    lastMessage = lastMessageDoc.data().message;
                    lastMessageTimestamp = lastMessageDoc.data().timestamp;
                  }

                  // Update the matchesList with the latest chat data
                  const matchIndex = matchesList.findIndex(
                    (match) => match.id === matchID
                  );
                  if (matchIndex !== -1) {
                    matchesList[matchIndex].message = lastMessage;
                    matchesList[matchIndex].at = lastMessageTimestamp;
                  } else {
                    matchesList.push({
                      id: matchID,
                      user: otherUser[0],
                      message: lastMessage,
                      at: lastMessageTimestamp,
                    });
                  }

                  // Call the callback with the updated matchesList
                  callback([...matchesList], true);
                },
                (error) => {
                  console.error("Error fetching chat messages: ", error);
                }
              );

              // Store the unsubscribe function for the Chat listener
              chatUnsubscribers.push(unsubscribeFromChat);
            })
            .catch((error) => {
              console.error("Error fetching user data: ", error);
            });
        });

        // Cleanup chat listeners on matches update
        return () => {
          chatUnsubscribers.forEach((unsub) => unsub());
        };
      },
      (error) => {
        console.error("Error fetching matches: ", error);
        callback([]);
      }
    );

    return unsubscribeFromMatches;
  }

  static async checkAndCreateMatch(likedUserID, currentUserID) {
    const CHAT_NODE = "Chat";
    const loggedInUserID = currentUserID; // Ensure this accurately fetches the current user's ID

    try {
      // First, check if a match between these two users already exists
      const matchesCollection = collection(db, MatchApi.collectionName);
      const matchesQuery = query(
        matchesCollection,
        where("users", "array-contains", loggedInUserID)
      );
      const snapshot = await getDocs(matchesQuery);

      const existingMatches = snapshot.docs.filter((doc) => {
        const users = doc.data().users || [];
        return users.includes(likedUserID);
      });

      // If a match already exists, do not create a new one
      if (existingMatches.length > 0) {
        console.log(
          `Match between ${loggedInUserID} and ${likedUserID} already exists.`
        );

        return;
      }

      // No existing match found, proceed with creating a new match document
      const newMatchData = {
        users: [loggedInUserID, likedUserID],
        [`${loggedInUserID}-on`]: serverTimestamp(),
      };
      const newMatchRef = await addDoc(matchesCollection, newMatchData);
      console.log("Match created successfully");

      // Correctly initialize the nested "Chat" collection using the newMatchRef's documentID
      const initialChatData = {
        timestamp: serverTimestamp(),
        message: "This chat will close in 14 days.",
      };
      const chatCollection = collection(
        db,
        MatchApi.collectionName,
        newMatchRef.id,
        CHAT_NODE
      );
      await addDoc(chatCollection, initialChatData);
    } catch (error) {
      console.error("Error creating match or initializing chat:", error);
    }
  }

  // TODO: should fetch by event ID
  static async fetchAllMatches() {
    const matchesCollection = collection(db, MatchApi.collectionName);
    const querySnapshot = await getDocs(matchesCollection);
    const matches = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return matches;
  }

  static async fetchMatchesByEventID(eventID) {
    const matchesCollection = collection(db, MatchApi.collectionName);
    const q = query(matchesCollection, where("eventID", "==", eventID));
    const querySnapshot = await getDocs(q);
    const matches = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return matches;
  }
}

export default MatchApi;
