import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "./context/GlobalContext";
import { PrimaryButton } from "./components/Button";
import { Input } from "./components/Input";
import Events from "./Events";
import Orders from "./Orders";
import Menu from "./Menu";
import VendorAPI from "./api/VendorApi";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
  const navigate = useNavigate();

  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [vendor, setVendor] = useState(null);
  const [vendorName, setVendorName] = useState("");

  useEffect(() => {
    const fetchVendorName = async () => {
      if (globalState.vendorID) {
        const vendor = await VendorAPI.getVendorById(globalState.vendorID);

        if (vendor) {
          setVendor(vendor);
        }
      }
    };

    fetchVendorName();
  }, [globalState.vendorID]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const vendor = await VendorAPI.updateVendor(
      globalState.vendorID,
      "name",
      vendorName
    );

    setGlobalState((prevState) => ({
      ...prevState,
      vendor,
    }));
    setVendorName(vendor.name);
    setVendor(vendor);
  };

  if (globalState.loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {vendor && vendor.name ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900 p-8">
            {vendor.name}'s Dashboard
          </h1>
          <div className="space-y-12">
            <Events />
            <Orders />
            <Menu />
          </div>
        </>
      ) : (
        <div>
          <div className="flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <h2 className="text-2xl font-semibold text-gray-900">
                    Create your Vendor Profile
                  </h2>
                  <div className="mt-2">
                    <Input
                      label="Vendor Name"
                      type="text"
                      value={vendorName}
                      onChange={(e) => setVendorName(e.target.value)}
                    />
                  </div>
                </div>
                <PrimaryButton type="submit">Create</PrimaryButton>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
