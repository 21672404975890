// UserAPI.js
import { db, storage } from "../firebase";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDoc,
  doc,
} from "firebase/firestore";
import { getDocs } from "firebase/firestore";

class UserAPI {
  static collectionName = "users"; // Static property for the collection name

  static async fetchUsersByIDs(values) {
    return UserAPI.fetchDocumentsByPropertyValues("userID", values);
  }

  static async getUserById(userID) {
    const usersCollection = collection(db, UserAPI.collectionName);
    const q = query(usersCollection, where("userID", "==", userID));
    const querySnapshot = await getDocs(q);

    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return users;
  }

  static async getUserById(userID) {
    const userDoc = await getDoc(doc(db, UserAPI.collectionName, userID));
    return userDoc.exists() ? userDoc.data() : null;
  }

  static async getUserByPartialName(name, limitCount = 5) {
    const usersCollection = collection(db, UserAPI.collectionName);
    const capitalizedName = this.capitalizeName(name);

    const q = query(
      usersCollection,
      where("name", ">=", capitalizedName),
      where("name", "<=", capitalizedName + "\uf8ff"),
      orderBy("name"),
      limit(limitCount)
    );
    const querySnapshot = await getDocs(q);

    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return users;
  }

  static capitalizeName(name) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }
}

export default UserAPI;
