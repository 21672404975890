import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  addDoc,
  getDoc,
  updateDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { GlobalContext } from "../context/GlobalContext";

const OrderForm = ({ usersDb }) => {
  const { orderID } = useParams();
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext);

  const [userIDs, setUserIDs] = useState([]);
  const [itemIDs, setItemIDs] = useState([]);
  const [itemPrice, setItemPrice] = useState(0);
  const [orderData, setOrderData] = useState({
    customer: "",
    itemID: "",
    quantity: 1,
    table: "",
    price: 0,
    status: "received",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = collection(db, "users");
      const usersSnapshot = await getDocs(usersRef);
      const users = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserIDs(users);
    };

    const fetchMenuItems = async () => {
      const menuCollection = collection(db, "menu");
      const q = query(
        menuCollection,
        where("vendorID", "==", globalState.vendorID)
      );
      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setItemIDs(items);
    };

    fetchUsers();
    fetchMenuItems();
  }, [globalState, globalState.vendorID]);

  useEffect(() => {
    if (orderID) {
      // Fetch order data if editing
      const fetchOrder = async () => {
        const orderRef = doc(db, "orders", orderID);
        const orderSnap = await getDoc(orderRef);
        if (orderSnap.exists()) {
          setOrderData(orderSnap.data());
        } else {
          console.log("No such document!");
        }
      };

      fetchOrder();
    }
  }, [orderID]);

  const handleChange = (field, value) => {
    setOrderData((prev) => ({
      ...prev,
      [field]: value,
      price: field === "quantity" ? value * itemPrice : prev.price,
    }));
  };

  const handleItemChange = async (value) => {
    const selectedItem = itemIDs.find((item) => item.id === value);
    const itemPrice = selectedItem ? selectedItem.price : 0;
    setItemPrice(itemPrice);
    setOrderData((prev) => ({
      ...prev,
      itemID: value,
      price: prev.quantity * itemPrice,
    }));
  };

  const deleteOrder = async () => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      const docRef = doc(db, "orders", orderID);
      await deleteDoc(docRef)
        .then(() => alert("Order deleted successfully!"))
        .catch((error) => console.error("Error deleting document: ", error));

      navigate("/orders");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(orderData);
    if (orderID) {
      // Update existing order
      const docRef = doc(db, "orders", orderID);
      const orderDataWithVendorID = {
        ...orderData,
        vendorID: globalState.vendorID,
      };
      await updateDoc(docRef, orderDataWithVendorID)
        .then(() => alert("Order updated successfully!"))
        .catch((error) => console.error("Error updating document: ", error));
      navigate("/orders");
    } else {
      const orderDataWithVendorID = {
        ...orderData,
        vendorID: globalState.vendorID,
      };
      await addDoc(collection(db, "orders"), orderDataWithVendorID);
      navigate("/orders");
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-md mt-6">
      <h2 className="text-xl font-semibold mb-4">
        {orderID ? "Edit Order" : "Create Test Order"}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="customer"
              className="block text-sm font-medium text-gray-700"
            >
              Customer
            </label>
            <select
              id="customer"
              value={orderData.customer}
              onChange={(e) => handleChange("customer", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryDark focus:ring-primaryDark sm:text-sm"
            >
              <option value="">Select a customer</option>
              {userIDs.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="item"
              className="block text-sm font-medium text-gray-700"
            >
              Item
            </label>
            <select
              id="item"
              value={orderData.itemID}
              onChange={(e) => handleItemChange(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryDark focus:ring-primaryDark sm:text-sm"
            >
              <option value="">Select an item</option>
              {itemIDs.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="quantity"
              className="block text-sm font-medium text-gray-700"
            >
              Quantity
            </label>
            <input
              type="number"
              id="quantity"
              value={orderData.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryDark focus:ring-primaryDark sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="table"
              className="block text-sm font-medium text-gray-700"
            >
              Table
            </label>
            <input
              type="text"
              id="table"
              value={orderData.table}
              onChange={(e) => handleChange("table", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryDark focus:ring-primaryDark sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              value={orderData.status}
              onChange={(e) => handleChange("status", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryDark focus:ring-primaryDark sm:text-sm"
            >
              <option value="received">Received</option>
              <option value="done">Done</option>
            </select>
          </div>
          <div className="mt-6 flex items-center justify-between">
            {orderID && (
              <button
                type="button"
                onClick={deleteOrder}
                className="text-sm font-semibold text-red-600"
              >
                Delete Order
              </button>
            )}
            <div className="flex items-center gap-x-6">
              <button
                type="button"
                onClick={() => navigate("/orders")}
                className="text-sm font-semibold text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-primaryDark focus:ring-offset-2"
              >
                {orderID ? "Update Order" : "Create Test Order"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderForm;
