export const RemovedList = ({ removedUsers }) => {
  const renderUserList = (users, title) => (
    <ul className="w-1/2 text-sm text-center font-medium bg-white border border-gray-200 rounded-lg">
      <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg text-white bg-red-300">
        {title}
      </li>
      {users.map((user, index) => (
        <li
          key={user.userID}
          className="w-full px-4 py-2 border-b border-gray-200 text-slate-700 text-nowrap text-ellipsis overflow-hidden"
        >
          {index + 1}. {user.name || "Unknown"}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="flex gap-4">
      {renderUserList(removedUsers.men, "Men")}
      {renderUserList(removedUsers.women, "Women")}
    </div>
  );
};
