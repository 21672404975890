import React, { useEffect, useState } from "react";
import EventApi from "../api/EventApi";

export const Reports = ({ eventID }) => {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(true);

  const onSelectReport = (index) => {
    const report = reports[index];
    setSelectedReport(report);
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const reportsData = await EventApi.fetchReportsByEventID(eventID);
        setReports(reportsData);
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  if (loading) {
    return <div>Loading reports...</div>;
  }

  if (reports.length === 0) {
    return <div>No reports found.</div>;
  }

  return (
    <div className="flex">
      <div className="w-1/3 p-4">
        <ul>
          {reports.map((report, index) => (
            <li key={index} className="mb-2">
              <button
                className="text-blue-500 hover:underline"
                onClick={() => onSelectReport(index)}
              >
                {report.reportName}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {selectedReport && (
        <div className="w-2/3 p-4 border-l">
          <div>
            <p>
              <strong>Reported person:</strong> {selectedReport.reportName}
            </p>
            <p>
              <strong>Reported by:</strong> {selectedReport.reportedByName}
            </p>
            <p>
              <strong>Reason:</strong> {selectedReport.note}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
