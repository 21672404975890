import React, { createContext, useEffect, useState, useContext } from "react";
import EventAPI from "../api/EventApi";

const EventStreamContext = createContext();

export const EventStreamProvider = ({ eventID, children }) => {
  const [eventUsersProfile, setEventUsersProfile] = useState([])
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!eventID) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const unsub = EventAPI.getEventStreaming(eventID, (data) => {
      setEventData((prev) => {
        if (!prev) return data;
        return { ...prev, ...data };
      });

      setLoading(false);
    });

    return () => {
      if (unsub) unsub();
    };
  }, [eventID]);

  return (
    <EventStreamContext.Provider value={{ eventData, loading, eventUsersProfile, setEventUsersProfile }}>
      {children}
    </EventStreamContext.Provider>
  );
};

export const useEventStream = () => useContext(EventStreamContext);
