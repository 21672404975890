import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { PrimaryButtonSmall, SecondaryButtonSmall } from "../components/Button";
import useImageResizer from "../utils/useImageResizer";

export default function Orders() {
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext);
  const [orders, setOrders] = useState([]);
  const { setImageForResize } = useImageResizer(600, 800);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!globalState.vendorID) return;
      const orderCollection = collection(db, "orders");
      const q = query(
        orderCollection,
        where("vendorID", "==", globalState.vendorID)
      );
      const querySnapshot = await getDocs(q);
      const ordersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch user data for each order
      const ordersWithUserData = await Promise.all(
        ordersData.map(async (order) => {
          const userRef = doc(db, "users", order.customer);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            return {
              ...order,
              customerData: userSnap.data(),
            };
          }
          return order;
        })
      );

      const ordersWithItemData = await Promise.all(
        ordersWithUserData.map(async (order) => {
          const itemRef = doc(db, "menu", order.itemID);
          const itemSnap = await getDoc(itemRef);
          if (itemSnap.exists()) {
            return {
              ...order,
              itemData: itemSnap.data(),
            };
          }
          return order;
        })
      );

      setOrders(ordersWithItemData);
    };

    fetchOrders();
  }, [globalState, globalState.vendorID]);

  const markAsdone = (orderID) => async () => {
    const orderRef = doc(db, "orders", orderID);
    const docSnap = await getDoc(orderRef);
    if (docSnap.exists()) {
      await updateDoc(orderRef, {
        status: "done",
      });
      setOrders((prevOrders) =>
        prevOrders.map((order) => {
          if (order.id === orderID) {
            return {
              ...order,
              status: "done",
            };
          }
          return order;
        })
      );
    }
  };
  const markAsReceived = (orderID) => async () => {
    const orderRef = doc(db, "orders", orderID);
    const docSnap = await getDoc(orderRef);
    if (docSnap.exists()) {
      await updateDoc(orderRef, {
        status: "received",
      });
      setOrders((prevOrders) =>
        prevOrders.map((order) => {
          if (order.id === orderID) {
            return {
              ...order,
              status: "received",
            };
          }
          return order;
        })
      );
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex mt-4 sm:mt-0 justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Orders</h1>
        <button
          type="button"
          onClick={() => navigate("/orders/new")}
          className="block rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-primary shadow-sm "
        >
          Create test order
        </button>
      </div>
      <div className="mt-8 flow-root mx-auto p-6 bg-white rounded-md shadow-md">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Table
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-500">
                      <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img
                            className="h-11 w-11 rounded-full"
                            src={order.customerData?.photoURL || ""}
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {order.customerData?.name || "Unknown"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className=" whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="mt-1">
                        {order.itemData?.name || "Unknown"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {order.quantity}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {order.table}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      ${order.price}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {order.status === "received" && (
                        <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                          {order.status.toUpperCase()}
                        </span>
                      )}

                      {order.status === "done" && (
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {order.status.toUpperCase()}
                        </span>
                      )}
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      {order.status === "received" ? (
                        <PrimaryButtonSmall onClick={markAsdone(order.id)}>
                          Mark as done
                        </PrimaryButtonSmall>
                      ) : (
                        <SecondaryButtonSmall
                          onClick={markAsReceived(order.id)}
                        >
                          Undo
                        </SecondaryButtonSmall>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
