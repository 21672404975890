import React, { useState, useContext, useRef, useEffect } from "react";
import Utility from "../utils/utility";
import EventAPI from "../api/EventApi";
import { GlobalContext } from "../context/GlobalContext";
import { useParams } from "react-router-dom";

export const Chat = ({ title, chatData, currentUserID, subCollectionName }) => {
  const [message, setMessage] = useState("");
  const { globalState } = useContext(GlobalContext);
  const { eventID } = useParams();

  const messagesEndRef = useRef(null);
  // Sort chatData by timestamp
  const sortedChatData = chatData.sort((a, b) => a.timestamp - b.timestamp);

  const handleSendMessage = async () => {
    if (message.trim() === "") return;

    try {
      await EventAPI.sendMessage(
        eventID,
        subCollectionName,
        message,
        currentUserID || globalState?.user?.userID || "",
        globalState?.user?.name || "Admin"
      );
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatData]);

  return (
    <div className="flex flex-col ">
      <div className="text-lg font-bold mb-2">{title}</div>
      <div className="flex-grow overflow-y-auto max-h-96 p-2 bg-gray-100 border border-gray-300 rounded-lg mb-2">
        {sortedChatData.length === 0 ? (
          <div className="text-slate-400 text-center">No messages</div>
        ) : (
          sortedChatData.map((chat) => (
            <div
              key={chat.id}
              className={`flex ${
                chat.userID === currentUserID ? "justify-end" : "justify-start"
              } mb-4`}
            >
              <div
                className={`max-w-xs p-2 rounded-lg  ${
                  chat.userID === currentUserID
                    ? "bg-primary text-white"
                    : "bg-slate-200"
                }`}
              >
                <div className="font-bold mb-1">{chat.name}</div>
                <div>{chat.message}</div>
                <div className="text-xs text-slate-400 mt-2 text-right hidden">
                  {Utility.formatDate(chat.timestamp)}
                </div>
              </div>
            </div>
          ))
        )}
        <div ref={messagesEndRef}></div>
      </div>
      <div className="flex items-center">
        <input
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded-lg mr-2"
        />
        <button
          onClick={handleSendMessage}
          className="px-4 py-2 bg-primary text-white rounded-lg"
        >
          Send
        </button>
      </div>
    </div>
  );
};
