import logo from "./logo.svg";
import "./App.css";
import React from "react";

import Login from "./Login";
import useAuthRedirect from "./useAuthRedirect";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Orders from "./Orders";
import Menu from "./Menu";
import Events from "./Events";
import { GlobalProvider } from "./context/GlobalContext";
import Home from "./Home";
import EventForm from "./Events/EventForm";
import OrderForm from "./Orders/OrderForm";
import MenuForm from "./Menu/MenuForm";
import PrivateAdminRoute from "./privateAdminRoute";
import Settings from "./Settings";
import { ToastContainer } from "react-toastify";
import AdminHome from "./AdminHome";
import AdminEvents from "./Admin/Events";
import AdminUsers from "./Admin/Users";
import AdminVendors from "./Admin/Vendors";
import AdminWaitlist from "./Admin/Waitlist";

const AuthHandler = () => {
  useAuthRedirect();
  return null;
};
function App() {
  return (
    <GlobalProvider>
      <ToastContainer />
      <Router>
        <AuthHandler />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={
              <Home>
                <Dashboard />
              </Home>
            }
          />
          <Route
            path="/events"
            element={
              <Home>
                <Events />
              </Home>
            }
          />
          <Route
            path="/settings"
            element={
              <Home>
                <Settings />
              </Home>
            }
          />
          <Route
            path="/events/new"
            element={
              <Home>
                <EventForm />
              </Home>
            }
          />
          <Route
            path="/events/:eventID/edit"
            element={
              <Home>
                <EventForm />
              </Home>
            }
          />
          <Route
            path="/orders"
            element={
              <Home>
                <Orders />
              </Home>
            }
          />
          <Route
            path="/orders/new"
            element={
              <Home>
                <OrderForm />
              </Home>
            }
          />
          <Route
            path="/menu"
            element={
              <Home>
                <Menu />
              </Home>
            }
          />
          <Route
            path="/menu/new"
            element={
              <Home>
                <MenuForm />
              </Home>
            }
          />
          <Route
            path="/menu/:itemID/edit"
            element={
              <Home>
                <MenuForm />
              </Home>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateAdminRoute>
                <Home>
                  <AdminUsers />
                </Home>
              </PrivateAdminRoute>
            }
          />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
