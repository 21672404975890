import React from "react";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from "../components/Accordion";
import { FormSection } from "./FormSection";
import { QueueSection } from "./QueueSection";
import {
  EventStreamProvider,
  useEventStream,
} from "../context/EventStreamingContext";
import { EventStreamChatProvider } from "../context/EventStreamingChatContext";
import { Matches } from "./Matches";
import { Reports } from "./Reports";
import { Reviews } from "./Reviews";
import { Chats } from "./Chats";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

const EventFormContent = ({ eventID }) => {
  const { eventData } = useEventStream();

  const accordionItems = [
    {
      title: eventID ? "Edit Event" : "Create Event",
      content: <FormSection />,
    },
    ...(eventID ? [{ title: "Queue", content: <QueueSection /> }] : []),
    ...(eventID
      ? [
          {
            title: "Chats",
            content: (
              <EventStreamChatProvider eventID={eventID}>
                <Chats />
              </EventStreamChatProvider>
            ),
          },
        ]
      : []),
    ...(eventID && eventData?.isCompleted
      ? [
          {
            title: "Matches",
            content: <Matches eventID={eventID} />,
          },
        ]
      : []),
    ...(eventID && eventData?.isCompleted
      ? [
          {
            title: "Reports",
            content: <Reports eventID={eventID} />,
          },
        ]
      : []),
    ...(eventID && eventData?.isCompleted
      ? [
          {
            title: "Reviews",
            content: <Reviews eventID={eventID} />,
          },
        ]
      : []),
  ];

  return (
    <div className="space-y-4">
      <a href="/events" className="text-primary px-3 py-2 flex items-center">
        <ChevronLeftIcon className="h-5 w-5 inline-block" />
        Back to Events
      </a>
      <h1 className="text-xl text-slate-600 text-center font-semibold my-6 max-w-4xl mx-auto text-nowrap text-ellipsis overflow-hidden">
        {eventData?.name}
      </h1>
      <Accordion items={accordionItems} />
    </div>
  );
};

const EventForm = () => {
  const { eventID } = useParams();

  return (
    <EventStreamProvider eventID={eventID}>
      <EventFormContent eventID={eventID} />
    </EventStreamProvider>
  );
};

export default EventForm;
