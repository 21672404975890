import React, { createContext, useContext, useEffect, useState } from "react";
import VendorAPI from "../api/VendorApi";

const VendorContext = createContext();

export const VendorProvider = ({ children }) => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendors = async () => {
      const vendorsList = await VendorAPI.fetchAllVendors();
      setVendors(vendorsList);
      setLoading(false);
    };

    fetchVendors();
  }, []);

  return (
    <VendorContext.Provider value={{ vendors, loading }}>
      {children}
    </VendorContext.Provider>
  );
};

export const useVendors = () => useContext(VendorContext);
