import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import UserDetailsPopup from "./UserDetailsPopup"; // Import the new component

export default function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // State for selected user
  const MAX_LENGTH = 50; // Maximum length for text display

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const userCollection = collection(db, "users");
        const querySnapshot = await getDocs(userCollection);
        const users = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Determine unique fields in the user documents
        const allFields = new Set();
        users.forEach((user) => {
          Object.keys(user).forEach((field) => {
            allFields.add(field);
          });
        });

        setFields(Array.from(allFields));
        setUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchAllUsers();
  }, []);

  // Function to truncate text and add ellipsis
  const truncateText = (text, maxLength) => {
    if (typeof text !== "string") {
      return text;
    }
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  // Function to display field content based on its type
  const displayField = (field) => {
    if (Array.isArray(field)) {
      return `${field.length} items`;
    }
    if (typeof field === "object" && field !== null) {
      return JSON.stringify(field);
    }
    if (typeof field === "string" && field.match(/https?:\/\/.+/)) {
      return (
        <img src={field} alt="User avatar" className="h-12 w-12 rounded-lg" />
      );
    }
    return truncateText(field, MAX_LENGTH);
  };

  // Function to handle row click
  const handleRowClick = (user) => {
    setSelectedUser(user);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedUser(null);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex mt-4 sm:mt-0 justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Users</h1>
      </div>
      <div className="mt-8 flow-root mx-auto p-6 bg-white rounded-md shadow-md">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {fields.map((field) => (
                    <th
                      key={field}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {field}
                    </th>
                  ))}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users.map((user) => (
                  <tr
                    key={user.id}
                    onClick={() => handleRowClick(user)}
                    className="cursor-pointer"
                  >
                    {fields.map((field) => (
                      <td
                        key={field}
                        className="whitespace-nowrap py-5 text-sm text-gray-500"
                      >
                        {displayField(user[field]) !== null && (
                          <div className="text-gray-900">
                            {displayField(user[field])}
                          </div>
                        )}
                      </td>
                    ))}
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a
                        href={`/admin/users/${user.id}/edit`}
                        className="text-primary hover:text-indigo-900"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/admin/users/${user.id}/edit`);
                        }}
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedUser && (
        <UserDetailsPopup user={selectedUser} onClose={handleClosePopup} />
      )}
    </div>
  );
}
