import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteField,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Utility from "../utils/utility";
import { showToast } from "../utils/showToast";

class EventAPI {
  static collectionName = "events";

  static async getAllEvents() {
    try {
      const querySnapshot = await getDocs(
        collection(db, EventAPI.collectionName)
      );
      const events = [];
      querySnapshot.forEach((doc) =>
        events.push({ id: doc.id, ...doc.data() })
      );
      return events;
    } catch (error) {
      throw error;
    }
  }
  static getEventStreaming(eventId, callback) {
    try {
      const docRef = doc(db, EventAPI.collectionName, eventId);
      return onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          callback({ id: doc.id, ...doc.data() });
        } else {
          console.log("No such document!");
        }
      });
    } catch (error) {
      throw error;
    }
  }
  static getEventStreamingWithGroupChatCount(
    eventId,
    subCollectionName,
    timestamp,
    callback
  ) {
    try {
      const docRef = doc(db, EventAPI.collectionName, eventId);

      return onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const eventData = { id: doc.id, ...doc.data() };

          const subCollectionRef = collection(
            db,
            EventAPI.collectionName,
            eventId,
            subCollectionName
          );

          if (!(timestamp instanceof Date)) {
            timestamp = Utility.convertDate(timestamp);
          }
          const q = query(subCollectionRef, where("timestamp", ">", timestamp));

          onSnapshot(q, (querySnapshot) => {
            const subCollectionCount = querySnapshot.size;
            callback({
              ...eventData,
              [`${subCollectionName}Count`]: subCollectionCount,
            });
          });
        } else {
          console.log("No such document!");
        }
      });
    } catch (error) {
      throw error;
    }
  }
  static async getEventsFor(userID, gender) {
    try {
      const propertyName = Utility.getGenderTypeFromGender(gender);
      const eventsQuery = query(
        collection(db, EventAPI.collectionName),
        where(propertyName, "array-contains", userID)
      );
      const querySnap = await getDocs(eventsQuery);
      const events = [];
      querySnap.forEach((doc) => events.push({ id: doc.id, ...doc.data() }));
      return events;
    } catch (err) {
      console.error(err);
    }
  }

  static async addUserToEvent(eventId, userId, gender) {
    const eventRef = doc(db, EventAPI.collectionName, eventId);
    const eventDoc = await getDoc(eventRef);
    const propertyName = Utility.getGenderTypeFromGender(gender);

    if (eventDoc.exists()) {
      const eventData = eventDoc.data();
      const users = eventData[propertyName] || [];

      if (!users.includes(userId)) {
        await updateDoc(eventRef, {
          [propertyName]: arrayUnion(userId),
        });
      } else {
        console.log(`User ${userId} is already in event ${eventId}.`);
      }
    } else {
      console.log(`Event ${eventId} does not exist.`);
    }
  }

  static async removeUserFromEvent(eventId, userId, gender) {
    const propertyName = Utility.getGenderTypeFromGender(gender);
    const eventRef = doc(db, EventAPI.collectionName, eventId);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      const eventData = eventDoc.data();
      const users = eventData[propertyName] || [];

      if (users.includes(userId)) {
        await updateDoc(eventRef, {
          [propertyName]: arrayRemove(userId),
        });
        console.log(`User ${userId} removed from event ${eventId}.`);
      } else {
        console.log(`User ${userId} is not in event ${eventId}.`);
      }
    } else {
      console.log(`Event ${eventId} does not exist.`);
    }
  }

  static async lastCheckedChat(eventID, userID) {
    const lastCheckedChatTS = {
      [`lastCheckedChat.${userID}`]: serverTimestamp(),
    };
    await EventAPI.updateEvent(eventID, lastCheckedChatTS);
  }

  static async updateEvent(eventID, valueObject) {
    try {
      const eventRef = doc(db, EventAPI.collectionName, eventID);

      // Fetch the document
      const docSnap = await getDoc(eventRef);

      if (!docSnap.exists()) {
        console.log("No matching event found.");
        return null;
      }

      // Update the document
      await updateDoc(eventRef, valueObject);

      // Fetch the updated document
      const updatedDoc = await getDoc(eventRef);
      const updatedEvent = updatedDoc.data();

      showToast("Event updated successfully");
      return updatedEvent;
    } catch (error) {
      console.error("Error updating event: ", error);
      throw error;
    }
  }

  static async deleteFieldProperty(documentId, fields) {
    // Reference to the specific document
    const docRef = doc(db, EventAPI.collectionName, documentId);

    // Update the document, deleting the specified field
    const updateObject = {};
    fields.forEach((field) => {
      updateObject[field] = deleteField();
    });

    await updateDoc(docRef, updateObject);

    // console.log(`Field "${field}" has been deleted from document "${documentId}" in collection "${collection}".`);
  }
  static getEventStreamingWithGroupChatData(
    eventId,
    subCollectionName,
    timestamp,
    callback
  ) {
    try {
      const docRef = doc(db, EventAPI.collectionName, eventId);

      return onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const eventData = { id: doc.id, ...doc.data() };

          const subCollectionRef = collection(
            db,
            EventAPI.collectionName,
            eventId,
            subCollectionName
          );

          if (!(timestamp instanceof Date)) {
            timestamp = Utility.convertDate(timestamp);
          }
          const q = query(subCollectionRef, where("timestamp", ">", timestamp));

          onSnapshot(q, (querySnapshot) => {
            const subCollectionData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            callback({
              ...eventData,
              [subCollectionName]: subCollectionData,
            });
          });
        } else {
          console.log("No such document!");
        }
      });
    } catch (error) {
      throw error;
    }
  }
  static async sendMessage(
    eventId,
    subCollectionName,
    message,
    userId,
    userName
  ) {
    try {
      const subCollectionRef = collection(
        db,
        EventAPI.collectionName,
        eventId,
        subCollectionName
      );
      console.log("message", message);
      console.log("name", userName);
      await addDoc(subCollectionRef, {
        message: message,
        userID: userId,
        name: userName,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      throw error;
    }
  }

  static async fetchReportsByEventID(eventID) {
    try {
      const eventRef = doc(db, EventAPI.collectionName, eventID);
      const eventDoc = await getDoc(eventRef);

      if (!eventDoc.exists()) {
        console.log("No matching event found.");
        return null;
      }

      const eventData = eventDoc.data();
      const reports = eventData.reports || [];
      return reports;
    } catch (error) {
      console.error("Error fetching reports: ", error);
      throw error;
    }
  }

  static async fetchReviewsByEventID(eventID) {
    try {
      const eventRef = doc(db, EventAPI.collectionName, eventID);
      const eventDoc = await getDoc(eventRef);

      if (!eventDoc.exists()) {
        console.log("No matching event found.");
        return null;
      }

      const eventData = eventDoc.data();
      const reviews = eventData.review || [];
      console.log("reviews", reviews);
      return reviews;
    } catch (error) {
      console.error("Error fetching reviews: ", error);
      throw error;
    }
  }
}

export default EventAPI;
