import React from "react";

const UserDetailsPopup = ({ user, onClose }) => {
  if (!user) return null;

  return (
    <div className="fixed inset-y-0 right-0 max-w-md w-full bg-white shadow-lg overflow-y-auto z-50">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-900">User Details</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            Close
          </button>
        </div>
        <div className="flex flex-col items-center">
          {user.photoURL && (
            <img
              src={user.photoURL}
              alt="User avatar"
              className="h-32 w-32 rounded-lg mb-4"
            />
          )}
          {Object.keys(user).map((field) => {
            if (user[field].startsWith && user[field].startsWith("https://")) {
              return null;
            }
            return (
              <div key={field} className="mb-2">
                <strong>{field}:</strong> {JSON.stringify(user[field], null, 2)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UserDetailsPopup;
