class Utility {
  static gender = {
    male: "Male",
    female: "Female",
  };
  static getOppositeGender(gender) {
    if (gender === Utility.gender.male) {
      return Utility.gender.female;
    }
    if (gender === Utility.gender.female) {
      return Utility.gender.male;
    }

    return gender;
  }
  static getGenderTypeFromGender(gender, toLocaleLowerCase = true) {
    let gType = "";
    switch (gender) {
      case Utility.gender.male:
        gType = "Men";
        break;
      case Utility.gender.female:
        gType = "Women";
        break;
      default:
        gType = "";
        break;
    }
    if (toLocaleLowerCase) {
      gType = gType.toLocaleLowerCase();
    }
    return gType;
  }
  static convertDate(date) {
    return new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
  }
  static formatDate(date, timeOnly = false) {
    if (!date) return date;

    if (!(date instanceof Date)) date = Utility.convertDate(date);

    // Days and months arrays for formatting
    const daysFull = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract date components
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();

    // Format hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Construct formatted date string
    const monthWeekDay = timeOnly ? "" : `${dayOfWeek}, ${month} ${day}`;
    return `${monthWeekDay} ${hours}:${minutes} ${ampm}`;
  }

  static formatDateSessionTime(seconds, includeDayHours = false) {
    const isNegative = seconds < 0;
    const absSeconds = Math.abs(seconds);

    const calculateTimeComponents = (absSeconds) => {
      const days = Math.floor(absSeconds / 86400);
      const remainingSecondsAfterDays = absSeconds % 86400;

      const hours = Math.floor(remainingSecondsAfterDays / 3600);
      const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;

      const minutes = Math.floor(remainingSecondsAfterHours / 60);
      const remainingSeconds = remainingSecondsAfterHours % 60;

      return { days, hours, minutes, remainingSeconds };
    };

    const formatWithLeadingZero = (num) => (num < 10 ? `0${num}` : num);

    const formatDays = (days) => (days > 0 ? `${days}d : ` : "");

    const formatHours = (hours, days, includeDayHours) =>
      days > 0 || includeDayHours ? `${formatWithLeadingZero(hours)}h : ` : "";

    const formatMinutes = (minutes, includeDayHours) =>
      includeDayHours
        ? `${formatWithLeadingZero(minutes)}m : `
        : `${formatWithLeadingZero(minutes)} : `;

    const formatSeconds = (seconds, includeDayHours) =>
      includeDayHours
        ? `${formatWithLeadingZero(seconds)}s`
        : formatWithLeadingZero(seconds);

    const { days, hours, minutes, remainingSeconds } =
      calculateTimeComponents(absSeconds);

    const formattedDays = formatDays(days);
    const formattedHours = formatHours(hours, days, includeDayHours);
    const formattedMinutes = formatMinutes(minutes, includeDayHours);
    const formattedSeconds = formatSeconds(remainingSeconds, includeDayHours);

    const formattedTime = `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;

    return `${isNegative ? "-" : ""}${formattedTime.trim()}`;
  }
}
export default Utility;
