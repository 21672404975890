// File: src/App.js
import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth, db, storage } from "./firebase";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { LinkButton, PrimaryButton } from "./components/Button";
import { Input } from "./components/Input";

function Login() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // Control flow of authentication steps
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  const getUserByID = async (userID) => {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("userID", "==", userID));
    const querySnapshot = await getDocs(q);

    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return users;
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    if (phone.length < 10) {
      alert("Please enter a valid phone number.");
      return;
    }

    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {}
    );

    let phoneNumber = phone.replace(/[^0-9]/g, "");
    phoneNumber = `+1${phoneNumber}`;

    signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setStep(2); // Move to OTP step
      })
      .catch((error) => {
        console.error("Error during sign in:", error);
      });
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    console.log("confirmationResult", confirmationResult);
    if (confirmationResult) {
      console.log("otp", otp);

      try {
        const { user } = await confirmationResult.confirm(otp);

        navigate("/home");
      } catch (error) {
        console.error("Error during code verification:", error);
      }
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-24 w-auto rounded-lg"
            src="/gist_logo.jpg"
            alt="gist"
          />
        </div>
        {step == 1 && (
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <div className="mt-2">
                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone number
                    </label>
                    <div className="mt-2 flex space-x-2">
                      <select className="px-4 block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                        <option>+1</option>
                      </select>
                      <input
                        onChange={(e) => setPhone(e.target.value)}
                        id="phone"
                        name="phone"
                        autoComplete="phone"
                        required
                        className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <PrimaryButton onClick={onSignInSubmit}>Next</PrimaryButton>
              <div className="mt-2">
                <div id="recaptcha-container"></div>
              </div>
            </form>
          </div>
        )}
        {step == 2 && (
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <div className="mt-2">
                  <Input
                    label="Enter OTP"
                    type="text"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
              </div>

              <PrimaryButton onClick={verifyOtp}>Verify OTP</PrimaryButton>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
