import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  doc,
  addDoc,
  getDoc,
  updateDoc,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { GlobalContext } from "../context/GlobalContext";
import useImageResizer from "../utils/useImageResizer";
import { Input } from "../components/Input";
import { Checkbox } from "../components/Checkbox";

const MenuForm = () => {
  const { itemID } = useParams();
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext);
  const { setImageForResize } = useImageResizer(600, 800);

  const [menuData, setMenuData] = useState({
    name: "",
    price: 0,
    photos: [],
    idRequired: false,
  });

  useEffect(() => {
    if (itemID) {
      // Fetch item data if editing
      const fetchItem = async () => {
        const itemRef = doc(db, "menu", itemID);
        const itemSnap = await getDoc(itemRef);
        if (itemSnap.exists()) {
          setMenuData(itemSnap.data());
        } else {
          console.log("No such document!");
        }
      };

      fetchItem();
    }
  }, [itemID, db]);

  const handleChange = (field, value) => {
    setMenuData((prev) => ({ ...prev, [field]: value }));
  };

  const handlePhotoChange = async (event) => {
    const files = event.target.files;
    const newPhotos = await Promise.all(
      Array.from(files).map((photo) => setImageForResize(photo))
    );
    handleChange("photos", [...menuData.photos, ...newPhotos]);
  };

  const removePhoto = (index) => {
    const updatedPhotos = menuData.photos.filter((_, idx) => idx !== index);
    handleChange("photos", updatedPhotos);
  };

  const deleteItem = async () => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const docRef = doc(db, "menu", itemID);
      await deleteDoc(docRef)
        .then(() => alert("Item deleted successfully!"))
        .catch((error) => console.error("Error deleting document: ", error));

      navigate("/menu");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (itemID) {
      // Update existing item
      const docRef = doc(db, "menu", itemID);
      const photoURLs =
        menuData.photos.length > 0
          ? await Promise.all(
              menuData.photos.map(async (photo) => {
                if (typeof photo == "string") return photo;

                const photoRef = ref(
                  storage,
                  `menu/${itemID}/itemPhotos/${photo.name}`
                );
                await uploadBytes(photoRef, photo);
                return getDownloadURL(photoRef);
              })
            )
          : [];
      const updatedMenuData = {
        ...menuData,
        photos: photoURLs, // This array will be empty if no photos were uploaded
      };

      await updateDoc(docRef, updatedMenuData)
        .then(() => alert("Item updated successfully with photos!"))
        .catch((error) =>
          console.error("Error updating document with photos: ", error)
        );
      navigate("/menu");
    } else {
      const menuDataForSaving = {
        ...menuData,
        photos: [],
        vendorID: globalState.vendorID,
      };

      const docRef = await addDoc(collection(db, "menu"), menuDataForSaving);
      const photoURLs =
        menuData.photos.length > 0
          ? await Promise.all(
              menuData.photos.map(async (photo) => {
                const photoRef = ref(
                  storage,
                  `menu/${docRef.id}/itemPhotos/${photo.name}`
                );
                await uploadBytes(photoRef, photo);
                return getDownloadURL(photoRef);
              })
            )
          : [];

      if (photoURLs.length > 0) {
        await updateDoc(docRef, { photos: photoURLs }) // Update the document with photo URLs
          .then(() => alert("New item created successfully with photos!"))
          .catch((error) =>
            console.error("Error adding photos to new item: ", error)
          );
      }
      navigate("/menu");
    }
  };

  const photoPreviews = menuData.photos?.map((photo, index) => {
    const url = typeof photo === "string" ? photo : URL.createObjectURL(photo);

    return (
      <div className="photoPreviewItem" key={index}>
        <img
          src={url}
          alt={`preview-${index}`}
          className="w-24 h-24 rounded-md mr-2"
        />
        <button
          type="button"
          onClick={() => removePhoto(index)}
          className="text-red-600"
        >
          Remove
        </button>
      </div>
    );
  });

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-md mt-6">
      <h2 className="text-xl font-semibold mb-4">
        {itemID ? "Edit Item" : "Create Item"}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <div>
            <Input
              label="Name"
              value={menuData.name}
              type="text"
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="photos"
              className="block text-sm font-medium text-gray-700"
            >
              Photos
            </label>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handlePhotoChange}
              className="mt-1 block w-full text-gray-500"
            />
            <div className="flex mt-2">{photoPreviews}</div>
          </div>
          <div>
            <Input
              label="Price"
              type="number"
              value={menuData.price}
              onChange={(e) => handleChange("price", e.target.value)}
            />
          </div>
          <div>
            <Checkbox
              label="ID Required"
              checked={menuData.vegetarian}
              onChange={(e) => handleChange("idRequired", e.target.checked)}
            />
          </div>
          <div className="mt-6 flex items-center justify-between">
            {itemID && (
              <button
                type="button"
                onClick={deleteItem}
                className="text-sm font-semibold text-red-600"
              >
                Delete Item
              </button>
            )}
            <div className="flex items-center gap-x-6">
              <button
                type="button"
                onClick={() => navigate("/menu")}
                className="text-sm font-semibold text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-primaryDark focus:ring-offset-2"
              >
                {itemID ? "Update Item" : "Create Item"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MenuForm;
