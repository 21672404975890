// src/utils/toast.js

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showToast = (message, options = {}) => {
  toast(message, {
    position: options.position || "top-right",
    autoClose: options.autoClose || 2000,
    hideProgressBar: options.hideProgressBar || false,
    closeOnClick: options.closeOnClick || true,
    pauseOnHover: options.pauseOnHover || true,
    draggable: options.draggable || true,
    ...options,
  });
};
