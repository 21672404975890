import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { LinkButton } from "../components/Button";
export default function Events() {
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      if (!globalState.vendorID) return;
      const eventCollection = collection(db, "events");
      const q = query(
        eventCollection,
        where("vendorID", "==", globalState.vendorID)
      );
      const querySnapshot = await getDocs(q);
      let events = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      events = events.sort((a, b) => {
        return b.date.toDate() - a.date.toDate();
      });
      // Set events to state
      setEvents(events);
    };
    fetchEvents();
  }, [globalState, globalState.vendorID]);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex mt-4 sm:mt-0 justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Events</h1>
        <button
          type="button"
          onClick={() => navigate("/events/new")}
          className="block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        >
          Add event
        </button>
      </div>
      <div className="mt-8 flow-root mx-auto p-6 bg-white rounded-md shadow-md">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Event name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date & Time
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Host name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Age range
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {events.map((event) => (
                  // TODO: Force id when creating event
                  <tr key={event.id}>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-500">
                      <LinkButton
                        onClick={() => navigate(`/events/${event.id}/edit`)}
                      >
                        <div className="text-primaryDark hover:text-indigo-900">{event.name}</div>
                      </LinkButton>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">
                        {event.date.toDate().toLocaleString()}
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {event.hostname}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {event.ageRange}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
