import { useContext, useEffect, useState } from "react";
import { Input } from "./components/Input";
import { GlobalContext } from "./context/GlobalContext";
import VendorAPI from "./api/VendorApi";
import { showToast } from "./utils/showToast";

export default function Settings() {
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const vendor = await VendorAPI.updateVendor(
      globalState.vendorID,
      "name",
      vendorName
    );

    setGlobalState((prevState) => ({
      ...prevState,
      vendor,
    }));
    showToast("Settings saved successfully");
  };
  const [vendorName, setVendorName] = useState(globalState?.vendor?.name || "");

  useEffect(() => {
    setVendorName(globalState?.vendor?.name || "");
  }, [globalState.vendor]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-md mt-6">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Settings
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <Input
                label="Vendor name"
                type="text"
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primaryDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
