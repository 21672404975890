import React, { useEffect, useState } from "react";
import MatchApi from "../api/MatchApi";
import { MatchChat } from "../components/MatchChat";

export const Matches = ({ eventID }) => {
  const [matches, setMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [loading, setLoading] = useState(true);

  const onSelectMatch = (matchId) => {
    const match = matches.find((m) => m.id === matchId);
    setSelectedMatch(match);
  };

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const matchesData = await MatchApi.fetchMatchesByEventID(eventID);
        setMatches(matchesData);
      } catch (error) {
        console.error("Error fetching matches:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  if (loading) {
    return <div>Loading matches...</div>;
  }

  if (matches.length === 0) {
    return <div>No matches found.</div>;
  }

  return (
    <div className="flex">
      <div className="w-1/3 p-4">
        <ul>
          {matches.map((match) => (
            <li key={match.id} className="mb-2">
              <button
                className="text-blue-500 hover:underline"
                onClick={() => onSelectMatch(match.id)}
              >
                {match.id}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {selectedMatch && (
        <div className="w-2/3 p-4 border-l">
          <h3 className="text-lg font-semibold mb-2">Match Details</h3>
          <div>
            <p>
              <strong>Match ID:</strong> {selectedMatch.id}
            </p>
            <p>
              <strong>Users:</strong> {selectedMatch.users.join(", ")}
            </p>
          </div>
          <MatchChat matchId={selectedMatch.id} />
        </div>
      )}
    </div>
  );
};
