import React, { useState, useEffect, useContext } from "react";
import { useVendors } from "./context/VendorContext";
import { GlobalContext } from "./context/GlobalContext";
import VendorAPI from "./api/VendorApi";

export default function VendorDropdown() {
  const { vendors, loading } = useVendors();
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [selectedVendor, setSelectedVendor] = useState(null);

  useEffect(() => {
    if (!loading && globalState.vendorID) {
      const defaultVendor = vendors.find(
        (vendor) => vendor.vendorID === globalState.vendorID
      );
      setSelectedVendor(defaultVendor || null);
    }
  }, [globalState.vendorID, vendors, loading]);

  const onSelectChange = async (event) => {
    const vendorID = event.target.value;
    const vendor = vendors.find((v) => v.vendorID === vendorID);
    setSelectedVendor(vendor);

    const fbVendor = await VendorAPI.getVendorById(vendor.vendorID);
    localStorage.setItem("vendor", JSON.stringify(fbVendor));
    localStorage.setItem("vendorID", vendor.vendorID);

    setGlobalState((prevState) => ({
      ...prevState,
      vendorID: vendor.vendorID,
      vendor: fbVendor,
    }));
  };

  if (loading) return null;

  return (
    <div className="relative mt-2 w-full">
      <select
        className="w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
        onChange={onSelectChange}
        value={selectedVendor ? selectedVendor.vendorID : ""}
      >
        <option value="" disabled>
          Select a vendor
        </option>
        {vendors.map((vendor) => (
          <option key={vendor.vendorID} value={vendor.vendorID}>
            {vendor.name}
          </option>
        ))}
      </select>
    </div>
  );
}
