import { collection, getDocs, query, where } from "firebase/firestore";

export const fetchUsersWithIDs = async (dbo, userIDs) => {
  if (userIDs == null || userIDs.length == 0) return [];

  const filteredUserIDs = userIDs.filter((uid) => uid != null);

  if (filteredUserIDs.length > 30) {
    const chunks = [];
    for (let i = 0; i < filteredUserIDs.length; i += 30) {
      chunks.push(filteredUserIDs.slice(i, i + 30));
    }

    const usersPromises = chunks.map((chunk) => fetchUserChunk(dbo, chunk));
    const usersArrays = await Promise.all(usersPromises);
    return usersArrays.flat();
  } else {
    return fetchUserChunk(dbo, filteredUserIDs);
  }
};

const fetchUserChunk = async (dbo, userIDs) => {
  const usersRef = collection(dbo, "users");
  const q = query(usersRef, where("userID", "in", userIDs));
  const querySnapshot = await getDocs(q);
  const users = [];
  querySnapshot.forEach((doc) => {
    users.push(doc.data());
  });
  return users;
};
