import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

export default function Menu() {
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      if (!globalState.vendorID) return;
      const menuCollection = collection(db, "menu");
      const q = query(
        menuCollection,
        where("vendorID", "==", globalState.vendorID)
      );
      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Set menu items to state
      setMenuItems(items);
    };
    fetchMenuItems();
  }, [globalState, globalState.vendorID]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex mt-4 sm:mt-0 justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Menu</h1>
        <button
          type="button"
          onClick={() => navigate("/menu/new")}
          className="block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primaryDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        >
          Add Item
        </button>
      </div>
      <div className="mt-8 flow-root mx-auto p-6 bg-white rounded-md shadow-md">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Photos
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Price
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {menuItems.map((item) => (
                  <tr key={item.id}>
                    <td className="whitespace-nowrap py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{item.name}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="flex flex-wrap gap-2">
                        {item.photos?.map((photo, index) => (
                          <img
                            key={index}
                            src={photo}
                            alt={`item-${index}`}
                            className="w-12 h-12 rounded-md"
                          />
                        ))}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      ${item.price}
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a
                        href={`/menu/${item.id}/edit`}
                        className="text-primary hover:text-indigo-900"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
