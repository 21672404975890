import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "./context/GlobalContext";

const PrivateAdminRoute = ({ children }) => {
  const { globalState } = useContext(GlobalContext);

  if (globalState.loading) return null;

  if (!globalState.isSuperAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateAdminRoute;
