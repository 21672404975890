import React, { createContext, useState, useEffect } from "react";
import Api from "../api/api";
import UserAPI from "../api/UserApi";
import VendorAPI from "../api/VendorApi";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    vendor: null,
    isAuthenticated: false,
    loading: true,
    isSuperAdmin: false,
    vendorID: null,
    user: null,
  });

  useEffect(() => {
    const unsubscribe = Api.onAuthStateChanged(async (fbUser) => {
      if (fbUser) {
        let vendor = localStorage.getItem("vendor")
          ? JSON.parse(localStorage.getItem("vendor"))
          : await VendorAPI.getVendorById(fbUser.uid);

        // user associated with vendor doesn't exist unless they are a super admin
        let user = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : await UserAPI.getUserById(fbUser.uid);

        // if vendor doesn't exist, create it
        if (!vendor) {
          vendor = await VendorAPI.createVendor(fbUser.uid);
        }

        localStorage.setItem("vendor", JSON.stringify(vendor));
        localStorage.setItem("vendorID", vendor.vendorID);

        // user associated with vendor doesn't exist unless they are a super admin

        if (user) {
          localStorage.setItem("user", JSON.stringify(user));
        }

        setGlobalState({
          isSuperAdmin: user?.isSuperAdmin,
          user,
          vendor,
          isAuthenticated: true,
          loading: false,
          vendorID: vendor.vendorID,
        });
      } else {
        localStorage.removeItem("vendor");
        localStorage.removeItem("user");
        localStorage.removeItem("vendorID");

        setGlobalState({
          isSuperAdmin: false,
          user: null,
          vendor: null,
          isAuthenticated: false,
          loading: false,
          vendorID: null,
        });
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
