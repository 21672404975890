import React from "react";
import "tailwindcss/tailwind.css";

export const Checkbox = ({ label, checked, onChange }) => {
  return (
    <div className="flex items-center">
      <label className="mr-2 text-gray-900">{label}</label>

      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="rounded text-primary focus:ring-primary"
      />
    </div>
  );
};
