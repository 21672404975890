import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";

export const SessionMetrics = ({
  name,
  metric,
  formatMetric,
  editEnabled,
  loading,
  renderEverySec,
  reRenderOnThisValueChange,
  increaseMetric,
  decreaseMetric,
  key,
}) => {
  const [metricDisplayValue, setMetricDisplayValue] = useState(metric);
  useEffect(() => {
    if (renderEverySec) {
      const intervalId = setInterval(() => {
        setMetricDisplayValue(formatMetric(metric));
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
    setMetricDisplayValue(formatMetric(metric));
  }, [metric, reRenderOnThisValueChange, formatMetric]);

  return (
    <div className="border-2 border-primaryDark rounded-md justify-around items-center p-4 relative">
      <div className="text-sm text-center text-primaryDark">{name}</div>
      {loading ? (
        <Loader />
      ) : metric == undefined || metric == null ? (
        <div className="text-sm text-center text-gray-300">-</div>
      ) : (
        <div className="flex gap-2 items-center justify-between">
          {editEnabled && (
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex justify-between w-[calc(100%-0px)] p-2 ">
              <button
                onClick={() => decreaseMetric(metric)}
                className="text-white border-2 border-primaryDark bg-primaryDark rounded-full"
              >
                <MinusIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => increaseMetric(metric)}
                className="text-white border-2 border-primaryDark bg-primaryDark rounded-full"
              >
                <PlusIcon className="h-5 w-5" />
              </button>
            </div>
          )}
          <h1 className="text-xl text-center text-primaryDark font-bold flex-1">
            {metricDisplayValue}
          </h1>
        </div>
      )}
    </div>
  );
};
