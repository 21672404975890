// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// Add more imports as needed

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBrnNqPVWwQf_HX1lKbKWa667ipHfCoRlM",
  authDomain: "speed-dating-sf.firebaseapp.com",
  databaseURL: "https://speed-dating-sf-default-rtdb.firebaseio.com",
  projectId: "speed-dating-sf",
  storageBucket: "speed-dating-sf.appspot.com",
  messagingSenderId: "319524791655",
  appId: "1:319524791655:web:93537228c9e14afe2b3bc2",
};

const cloudFunctionsAPI = {
  locationID: "us-central1",

  getUrl: (functionName, locationID) => {
    return `https://${locationID}-${firebaseConfig.projectId}.cloudfunctions.net/${functionName}`
  }
}

// Initialize Firebaseㅐ
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const functions = getFunctions(app);

auth.settings.appVerificationDisabledForTesting = false;

// Check if the app is running in PWA mode
const isPwa = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone ||
    document.referrer.includes("android-app://")
  );
};

// Initialize Firestore with persistence if in PWA mode
const initializeFirestoreWithPersistence = async () => {
  try {
    const db = initializeFirestore(app, {
      localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
      }),
    });
    console.log("Persistence has been enabled.");
    return db;
  } catch (err) {
    if (err.code === "failed-precondition") {
      console.error("Persistence failed: Multiple tabs open");
    } else if (err.code === "unimplemented") {
      console.error("Persistence is not available in this browser");
    }
  }
  return getFirestore(app);
};

let db;
if (isPwa()) {
  db = await initializeFirestoreWithPersistence();
} else {
  console.log("Not running in PWA mode, persistence is not enabled");
  db = getFirestore(app);
}

const storage = getStorage(app);

export { auth, db, storage, functions, cloudFunctionsAPI };
