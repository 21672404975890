import React from "react";
import "tailwindcss/tailwind.css";

export const Input = ({ label, type, value, onChange }) => {
  return (
    <div>
      <label
        htmlFor="phone"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          onChange={onChange}
          id="phone"
          value={value}
          name="phone"
          type={type}
          autoComplete="phone"
          required
          className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
};
