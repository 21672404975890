import React, { useEffect, useState } from "react";
import Utility from "../utils/utility";
import MatchApi from "../api/MatchApi";

export const MatchChat = ({ matchId }) => {
  const [chatData, setChatData] = useState([]);
  const [chatStartTime, setChatStartTime] = useState(null);
  const [loadingChat, setLoadingChat] = useState(true);
  const [matchUsers, setMatchUsers] = useState([]);
  const [userColors, setUserColors] = useState({});

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        MatchApi.getMatchChat(matchId, "Chat", (error, data) => {
          if (error) {
            console.error("Error fetching chat data:", error);
            return;
          }

          const chatMessages = data.Chat || [];
          setChatData(chatMessages);

          if (chatMessages.length > 0) {
            const firstMessage = chatMessages[0];
            setChatStartTime(firstMessage.timestamp);
          }

          if (data.users) {
            setMatchUsers(data.users);
            // Assign different colors to different users
            setUserColors({
              [data.users[0]]: "bg-primary text-white",
              [data.users[1]]: "bg-slate-200",
            });
          }

          setLoadingChat(false);
        });
      } catch (error) {
        console.error("Error fetching chat data:", error);
      }
    };

    fetchChatData();
  }, [matchId]);

  // Sort chatData by timestamp
  const sortedChatData = chatData.sort((a, b) => a.timestamp - b.timestamp);

  if (loadingChat) {
    return <div>Loading chat...</div>;
  }

  return (
    <div className="flex flex-col p-4">
      {chatStartTime && (
        <div className="text-gray-500 text-sm mb-2">
          Chat started at: {Utility.formatDate(chatStartTime)}
        </div>
      )}
      <div className="flex-grow overflow-y-auto p-4 bg-gray-100 border border-gray-300 rounded-lg mb-4">
        {sortedChatData.length === 0 ? (
          <div className="text-gray-500 text-center">No messages</div>
        ) : (
          sortedChatData.map((chat) => (
            <div
              key={chat.id}
              className={`flex ${
                chat.userID === matchUsers[0] ? "justify-end" : "justify-start"
              } mb-4`}
            >
              <div
                className={`max-w-xs p-4 rounded-lg ${userColors[chat.userID]}`}
              >
                <div className="font-bold mb-1">{chat.name}</div>
                <div>{chat.message}</div>
                <div className="text-xs text-gray-500 mt-2 text-right">
                  {Utility.formatDate(chat.timestamp)}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
