import React, { useState } from "react";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";

const Accordion = ({ items }) => {
  const [openIndices, setOpenIndices] = useState([]);

  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <div>
      {items.map((item, index) => (
        <div
          key={index}
          className="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-md mt-6"
        >
          <button
            className="w-full text-left font-semibold focus:outline-none text-xl flex justify-between"
            onClick={() => toggleAccordion(index)}
          >
            {item.title}
            <ChevronDownIcon className="w-6 h-6" />
          </button>

          {openIndices.includes(index) && (
            <div className="mt-4">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
