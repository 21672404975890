import React, { useEffect, useState } from "react";
import EventApi from "../api/EventApi";

export const Reviews = ({ eventID }) => {
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [loading, setLoading] = useState(true);

  const onSelectReview = (index) => {
    const review = reviews[index];
    setSelectedReview(review);
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await EventApi.fetchReviewsByEventID(eventID);
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return <div>Loading reviews...</div>;
  }

  if (reviews.length === 0) {
    return <div>No reviews found.</div>;
  }

  return (
    <div className="flex">
      <div className="w-1/3 p-4">
        <ul>
          {reviews.map((review, index) => (
            <li key={index} className="mb-2">
              <button
                className="text-blue-500 hover:underline truncate w-full"
                onClick={() => onSelectReview(index)}
              >
                {review.name || review.userID}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {selectedReview && (
        <div className="w-2/3 h-full p-4 border-l">
          <div >
            {
              Object.keys(selectedReview).map((field, idx) => {
                return <p key={idx} className="text-ellipsis break-words w-full my-2">
                  <strong>{field}</strong>
                  <br />
                  {selectedReview[field]}
                </p>
              })

            }
          </div>
        </div>
      )}
    </div>
  );
};
