import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import Loader from "./Loader";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function ImageModal({ name, avatarUrl, setOpen }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = avatarUrl;
    img.onload = () => setIsLoading(false);
  }, [avatarUrl]);

  return (
    <Dialog
      open={true}
      onClose={() => setOpen(false)}
      className="relative z-10 "
    >
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-10 flex items-center justify-center w-dvw h-4/5 top-20">
        <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 h-full w-full max-w-96">
          <button
            className="absolute top-0 right-0 m-3 text-gray-500 hover:text-gray-700"
            onClick={() => setOpen(false)}
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
          <div className="h-full">
            <div className="mt-3 text-center sm:mt-5 h-full">
              <DialogTitle
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                {name}
              </DialogTitle>
              <div className="mt-2 [height:calc(100%-50px)] ">
                {isLoading ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <img
                    src={avatarUrl}
                    alt="avatar"
                    className="h-full w-full  object-cover"
                  />
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
