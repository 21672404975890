// VendorAPI.js
import { db, storage } from "../firebase";
import { collection, query, where } from "firebase/firestore";
import {
  getDocs,
  getDoc,
  updateDoc,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";

class VendorAPI {
  static collectionName = "vendors"; // Static property for the collection name

  static async fetchVendorsByIDs(values) {
    return VendorAPI.fetchDocumentsByPropertyValues("vendorID", values);
  }

  static async fetchAllVendors() {
    const vendorsCollection = collection(db, VendorAPI.collectionName);
    const querySnapshot = await getDocs(vendorsCollection);

    const vendors = [];
    querySnapshot.forEach((doc) => {
      vendors.push({
        vendorID: doc.data().vendorID,
        name: doc.data().name || "New vendor",
      });
    });

    return vendors;
  }
  static async fetchDocumentsByPropertyValues(property, values) {
    const MAX_BATCH_SIZE = 10;
    const batches = [];

    for (let i = 0; i < values.length; i += MAX_BATCH_SIZE) {
      const batchValues = values.slice(i, i + MAX_BATCH_SIZE);
      batches.push(VendorAPI.fetchBatch(property, batchValues));
    }

    const results = await Promise.all(batches);
    return results.flat();
  }

  static async fetchBatch(property, values) {
    const q = query(
      collection(db, VendorAPI.collectionName),
      where(property, "in", values)
    );

    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map((docSnap) => ({
      id: docSnap.id,
      ...docSnap.data(),
    }));

    return documents;
  }

  // static async getVendorById(vendorID) {
  //   const vendorsCollection = collection(db, VendorAPI.collectionName);
  //   const q = query(vendorsCollection, where("vendorID", "==", vendorID));
  //   const querySnapshot = await getDocs(q);

  //   const vendors = [];
  //   querySnapshot.forEach((doc) => {
  //     vendors.push({ id: doc.id, ...doc.data() });
  //   });

  //   return vendors;
  // }

  static async updateVendor(vendorID, property, value) {
    try {
      const vendorsRef = collection(db, "vendors");
      const q = query(vendorsRef, where("vendorID", "==", vendorID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching vendor found.");
        return null;
      }

      let updatedVendor = null;

      for (const doc of querySnapshot.docs) {
        await updateDoc(doc.ref, {
          [property]: value,
        });

        // Fetch the updated document
        const updatedDoc = await getDoc(doc.ref);
        updatedVendor = updatedDoc.data();
      }

      console.log("updatedVendor", updatedVendor);
      localStorage.setItem("vendor", JSON.stringify(updatedVendor));
      console.log("Vendor updated successfully.");
      return updatedVendor;
    } catch (error) {
      console.error("Error updating vendor: ", error);
      throw error;
    }
  }

  static async createVendor(userID) {
    try {
      const vendorDoc = doc(db, "vendors", userID);
      const vendorSnapshot = await getDoc(vendorDoc);
      let vendor;
      if (vendorSnapshot.exists()) {
        console.log("Vendor already exists.");
        return vendorDoc;
      } else {
        console.log("Vendor does not exist.");
        vendor = await setDoc(vendorDoc, { vendorID: userID, userID });
      }

      console.log("Vendor created successfully.", vendorSnapshot);

      return vendorSnapshot;
    } catch (error) {
      console.error("Error creating vendor: ", error);
      throw error;
    }
  }

  static async getVendorById(vendorID) {
    const vendorDoc = await getDoc(doc(db, "vendors", vendorID));
    return vendorDoc.exists() ? vendorDoc.data() : null;
  }

  static async addVendor(vendor) {
    const vendorRef = doc(db, "vendors", vendor.vendorID);
    await setDoc(vendorRef, vendor);
    console.log("Vendor added successfully.");
    return vendor;
  }
}

export default VendorAPI;
