import React from "react";
import "tailwindcss/tailwind.css";

export const PrimaryButton = ({ onClick, children }) => {
  return (
    <button
      className="text-white bg-primary py-2 px-4 rounded hover:bg-primary-dark w-full"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const PrimaryButtonSmall = ({ onClick, children, loading }) => {
  return (
    <button
      disabled={loading}
      className="text-white bg-primary py-2 px-4 rounded hover:bg-primary-dark disabled:opacity-50"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const SecondaryDangerButtonSmall = ({ onClick, children, loading }) => {
  return (
    <button
      disabled={loading}
      className="bg-red-500 text-yellow-50 border border-red-500 py-2 px-4 rounded disabled:opacity-50"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export const SecondaryButtonSmall = ({ onClick, children }) => {
  return (
    <button
      className="bg-white text-primary border border-primary py-2 px-4 rounded"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const SecondaryButton = ({ onClick, children }) => {
  return (
    <button
      className="bg-white text-primary border border-primary py-2 px-4 rounded"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const LinkButton = ({ onClick, children }) => {
  return (
    <button
      className="text-primary focus:no-underline hover:no-underline"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
