import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "./context/GlobalContext";

const useAuthRedirect = () => {
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext);

  useEffect(() => {
    if (globalState.loading) return;

    const { isSuperAdmin, isAuthenticated } = globalState;
    const path = window.location.pathname;

    if (!isAuthenticated) {
      if (path !== "/") navigate("/");
    } else {
      if (path === "/") {
        navigate("/home");
      } else if (path.startsWith("/admin") && !isSuperAdmin) {
        navigate("/home");
      }
    }
  }, [globalState, navigate]);
};

export default useAuthRedirect;
